<template>
  <div class="main-container position-relative py-0 py-md-5">
    <div class="row py-5 justify-content-center">
      <div class="col-10 col-lg-8 mb-5" v-if="part != null">
        <p class="font-bold font-size-3 text-dark">
          <span
            class="pointer"
            @click="$common.showChapterModal(part.chapter.id)"
            >{{ part.chapter.name }}</span
          >
          &nbsp;>&nbsp;{{ part.name }}
        </p>
      </div>
      <div class="w-100"></div>
      <div class="col-1 p-0 p-lg-3 center-vertically-flex justify-content-end">
        <p class="pointer font-size-1" v-if="previousPart != null">
          <router-link
            :to="'/part/' + previousPart.id"
            :title="previousPart.name"
          >
            <i class="text-dark fas fa-chevron-left"></i>
          </router-link>
        </p>
      </div>
      <div class="col-10 col-lg-8">
        <div
          id="vimeo-player-div"
          class="position-relative"
          :style="'min-height:' + vimeoPlayerHeight + 'px'"
        >
          <div
            v-if="!vimeoReady"
            class="centered-axis-xy w-100 h-100 bg-dark center-vertically-flex justify-content-center"
          >
            <div id="loader">
              <div class="planet-top"></div>
              <div class="planet-bottom"></div>
              <div class="ring"></div>
            </div>
          </div>
          <vimeo-player
            v-if="vimeoPlayerWidth > 0 && videoUrl.length"
            ref="player"
            :video-url="videoUrl"
            video-id=""
            :player-width="vimeoPlayerWidth"
            :player-height="vimeoPlayerHeight"
            @progress="listenVideoProgress"
            @ready="vimeoReady = true"
            :key="'vimeo-player-' + vimeoPlayerKey"
          />
        </div>
      </div>
      <div
        class="col-1 p-0 p-lg-3 center-vertically-flex justify-content-start"
      >
        <p class="pointer font-size-1" v-if="nextPart != null">
          <router-link :to="'/part/' + nextPart.id" :title="nextPart.name">
            <i class="text-dark fas fa-chevron-right"></i>
          </router-link>
        </p>
      </div>
      <div class="w-100 mb-5"></div>
      <div class="col-10 col-lg-8 mb-4" v-if="part != null && !isPartValidated">
        <p class="font-size-4 text-light">
          Après le visionnage de la vidéo, celle-ci sera marquée comme lue et la
          cheat sheet sera débloquée.
        </p>
        <p class="font-size-4 text-light mb-4">
          Vous pouvez aussi forcer ces actions en cliquant si dessous :
        </p>
        <button
          type="button"
          class="btn btn-lg font-bold font-size-4"
          :class="'btn-outline-chapter-' + part.chapter.id"
          @click="validatePart"
        >
          MARQUER COMME LUE
        </button>
      </div>
      <div class="col-10 col-lg-8 mb-3" v-if="part != null && isPartValidated">
        <div
          class="btn btn-lg font-size-4 font-bold mr-5 mb-2"
          :class="'btn-chapter-' + part.chapter.id"
        >
          VIDEO LUE<i class="ml-4 fas fa-check"></i>
        </div>
        <div
          class="btn btn-lg font-size-4 font-bold mb-2"
          :class="'btn-chapter-' + part.chapter.id"
        >
          CHEAT SHEET DEBLOQUEE<i class="ml-4 fas fa-check"></i>
        </div>
      </div>
      <div
        class="col-10 col-lg-8 mb-4 mt-3"
        v-if="part != null && isPartValidated"
      >
        <cheat-sheet
          class="rounded py-2 w-100 w-md-75"
          v-if="part"
          :chapter-id="part.chapter.id"
          :text="cheatsheet"
          :title="part.name"
          :links="links"
          :small-padding="true"
        >
        </cheat-sheet>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.$common.getPageMeta();
  },
  jsonld() {
    return this.$common.getJsonLd();
  },
  data() {
    return {
      part: null,
      videoUrl: "",

      isPartValidated: false,

      vimeoPlayerWidth: 0,
      vimeoPlayerHeight: 0,
      vimeoPlayerKey: 0,
      vimeoPlayerTimeoutUpdate: 0,

      cheatsheet: "",
      links: [],

      nextPart: null,
      previousPart: null,

      vimeoReady: false,
    };
  },
  methods: {
    getNextPart() {
      var indexPart = this.part.chapter.parts.findIndex(
        (p) => p.id == this.part.id
      );

      if (indexPart < this.part.chapter.parts.length - 1) {
        this.nextPart = this.part.chapter.parts[indexPart + 1];
      } else {
        var chapters = this.$common.getSummary().chapters;
        var indexChapter = chapters.findIndex(
          (c) => c.id == this.part.chapter.id
        );
        if (indexChapter == chapters.length - 1) return;
        this.nextPart = chapters[indexChapter + 1].parts[0];
      }
    },
    getPreviousPart() {
      var indexPart = this.part.chapter.parts.findIndex(
        (p) => p.id == this.part.id
      );

      if (indexPart > 0) {
        this.previousPart = this.part.chapter.parts[indexPart - 1];
      } else {
        var chapters = this.$common.getSummary().chapters;
        var indexChapter = chapters.findIndex(
          (c) => c.id == this.part.chapter.id
        );
        if (indexChapter == 0) return;
        this.previousPart =
          chapters[indexChapter - 1].parts[
            chapters[indexChapter - 1].parts.length - 1
          ];
      }
    },
    setVimeoPlayerSize() {
      const ratio = 16 / 9;
      const playerDiv = document.getElementById("vimeo-player-div");
      this.vimeoPlayerWidth = playerDiv.clientWidth;
      this.vimeoPlayerHeight = this.vimeoPlayerWidth / ratio;
      //update with timeoutt
      if (this.vimeoPlayerTimeoutUpdate != null)
        clearTimeout(this.vimeoPlayerTimeoutUpdate);
      this.vimeoPlayerTimeoutUpdate = setTimeout(() => {
        this.vimeoPlayerKey++;
      }, 200);
    },
    listenVideoProgress(e) {
      if (!this.isPartValidated && e.percent >= 0.75) {
        this.validatePart();
      }
    },
    validatePart() {
      this.$database.markPartAsValidated(this.part.id);
      this.isPartValidated = true;
    },
    getPrivatePartInfo() {
      this.$api.parts
        .getInfo(this.part.id)
        .then((res) => {
          this.videoUrl = res.data.url;
          this.cheatsheet = res.data.cheatsheet;
          this.links = res.data.links;
        })
        .catch((e) => {
          console.log(e);
          return this.$router.push("/");
        });
    },
    setDelayedPartAsLast() {
      setTimeout(() => {
        this.$database.setLastPartId(this.part.id);
      }, 2000);
    },
  },
  created: function () {
    if (!this.$common.checkIfPartIDExist(this.$route.params.id))
      return this.$router.push("/");

    this.part = this.$common.getPartInformation(this.$route.params.id);

    this.getPrivatePartInfo();

    this.isPartValidated = this.$database.checkPartValidated(this.part.id);

    this.getNextPart();
    this.getPreviousPart();
  },
  mounted: function () {
    this.setVimeoPlayerSize();
    this.setDelayedPartAsLast();
    window.addEventListener("resize", this.setVimeoPlayerSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.setVimeoPlayerSize);
  },
};
</script>
